import logo from './firebase-logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Sample Dynamic links to the att app
        </p>
        
        <br></br>
        <a href="https://alecko.page.link/Kxsd">iPhone 13</a>
        <br></br>
        <br></br>
        <a href="https://alecko.page.link/bqu5">Payment Agreement</a>
        <br></br>
        <a href="https://test.doronbali.com/test">https://test.doronbali.com/test</a>
        <br></br>
        <a href="https://finalstage.att.com">https://finalstage.att.com</a>
        <br></br>
        <a href="https://auth.stage.clogin.att.com/DA_lKpZAt_OitmqB-XHakEUi5K2bVPAFSAog82rBS8Em4Q">https://auth.stage.clogin.att.com/...</a>
        <br></br>
        <a href="https://auth.att.com/DA_4LZFh6Rtlh391CoJuUlt7BFRn2FxPp3vTMDfEXz-o">https://auth.att.com/...</a>
        <br></br>
        <a href="https://localhost:8080/DA_4LZFh6Rtlh391CoJuUlt7BFRn2FxPp3vTMDfEXz-o">localhost:8080</a>
      </header>
    </div>
  );
}

export default App;
